<template>
  <section class="min-w1600">
    <div class="searchwrap">
      <div class="searchBar">
         <div class="box-ui-select searchbar-content">
           <div class="title"><span>{{ $t('searchArea.date') }}</span></div>
           <div class="fx">
             <date-selector :class="'searchbar-content'"
                           @setStartDate="setStartDate"
                           @setEndDate="setEndDate"
                           :startDefault="startDefault"
                           :endDefault="endDefault"
                           :configs="dateConfigs"
                           :isOldYn="true"
                           @setOldYn="setOldYn" :oldYn="reqData.oldYn" />
           </div>
         </div>
      </div>
      <div class="searchBar">
        <div class="box-ui-toggle searchbar-content">
          <div class="title"><span>{{ $t('common.condition') }}</span></div>
          <div class="toggle-container">
            <div class="toggle-item">
              <span class="toggle-title">{{ $t('common.includeDelMem') }}</span>
              <input type="checkbox" class="set-switch" v-model="reqData.memDelYn" />
            </div>
          </div>
        </div>

        <div class="searchbar-content">
          <div class="title"><span>{{ $t('searchArea.DBselect') }}</span></div>
          <select>
            <option value="current">{{ $t('searchArea.pre') }}</option>
            <option value="2021">2021</option>
          </select>
        </div>
        <button class="btn-search" type="button" @click="pageSeach">
          <i class="fas fa-search"></i>
        </button>
      </div>
      <memo></memo>
    </div>
    <div class="main-contents-wrapper">
      <ul class="tab-wrap">
        <li @click="currentTab = 'all'" :class="{'active': currentTab === 'all'}" class="tab-item">{{ $t('common.all') }}</li>
        <li @click="currentTab = 'casino'" :class="{'active': currentTab === 'casino'}" class="tab-item">{{ $t('table.head.casino') }}</li>
        <li @click="currentTab = 'slot'" :class="{'active': currentTab === 'slot'}" class="tab-item">{{ $t('table.head.slot') }}</li>
        <li @click="currentTab = 'sport'" :class="{'active': currentTab === 'sport'}" class="tab-item">{{ $t('table.head.sport') }}</li>
        <li @click="currentTab = 'mini'" :class="{'active': currentTab === 'mini'}" class="tab-item">{{ $t('table.head.mini') }}</li>
      </ul>
      <div class="table-wrapper">
        <table class="mainTable">
          <thead>
            <tr>
              <th rowspan="2">{{ $t('table.head.gameType') }}</th>
              <th colspan="3">{{ $t('table.head.tran') }}</th>
              <th colspan="3">{{ $t('table.head.wl') }}</th>
              <th colspan="3">{{ $t('table.head.userCnt') }}</th>
              <th>{{ $t('table.head.rollingBonus') }}</th>
              <th>{{ $t('table.head.preRollOutPer') }}</th>
              <th>{{ $t('table.head.aftRollOutPer') }}</th>
            </tr>
            <tr>
              <th>{{ $t('table.head.normalUser') }}</th>
              <th>{{ $t('table.head.blacklist') }}</th>
              <th>{{ $t('common.total') }}</th>
              <th>{{ $t('table.head.normalUser') }}</th>
              <th>{{ $t('table.head.blacklist') }}</th>
              <th>{{ $t('common.total') }}</th>
              <th>{{ $t('table.head.normalUser') }}</th>
              <th>{{ $t('table.head.blacklist') }}</th>
              <th>{{ $t('common.total') }}</th>
              <th>{{ $t('table.head.normalUser') }}</th>
              <th>{{ $t('table.head.normalUser') }}</th>
              <th>{{ $t('table.head.normalUser') }}</th>
            </tr>
          </thead>
          <!-- todo: api -->
          <!-- <template v-if="betList.length !== 0">
              <tr v-for="(item, idx) in betList" :key="idx">
                <td>{{ pageInfo.tatal_list_count - idx }}</td>
                <td>
                  <button type="button" class="fc-id btn-link" @click="detailOpen('user', item.memId)">{{ item.memId }}</button>
                </td>
                <td>{{ item.provider }}</td>
                <td>{{ item.betIdx }}</td>
                <td style="width: 15%">{{ item.gameIdx }}</td>
                <td>{{ item.updDate }}</td>
                <td>-</td>
                <td>{{ item.betAmt }}</td>
                <td :class="item.wl ? 'fc-red' : 'fc-blue'">{{ item.wlAmt }}</td>
                <td>-</td>
                <td :class="item.wl ? 'fc-red' : 'fc-blue'">{{ item.result }}</td>
              </tr>
            </template> -->
          <template v-if="true">
            <tbody>
              <tr v-for="item in tableData" :key="item.code">
                <td>{{ item.codename }}</td>
                <td :class="{ 'fc-red': item.memBetAmt < 0 }" class="roboto">{{ numberWithCommas(item.memBetAmt) }}</td>
                <td :class="{ 'fc-red': item.blackMemBetAmt < 0 }" class="roboto">{{ numberWithCommas(item.blackMemBetAmt) }}</td>
                <td :class="{ 'fc-red': item.memBetAmt + item.blackMemBetAmt < 0 }" class="roboto">{{ numberWithCommas(item.memBetAmt + item.blackMemBetAmt) }}</td>

                <td :class="{ 'fc-red': item.memWlAmt < 0 }" class="roboto">{{ numberWithCommas(item.memWlAmt) }}</td>
                <td :class="{ 'fc-red': item.blackWlBetAmt < 0 }" class="roboto">{{ numberWithCommas(item.blackWlBetAmt) }}</td>
                <td :class="{ 'fc-red': item.memWlAmt + item.blackWlBetAmt < 0 }" class="roboto">{{ numberWithCommas(item.memWlAmt + item.blackWlBetAmt) }}</td>

                <td class="roboto">{{ item.memCnt }}</td>
                <td class="roboto">{{ item.blackMemCnt }}</td>
                <td class="roboto">{{ item.memCnt + item.blackMemCnt }}</td>

                <td :class="{ 'fc-red': item.memBetAmt < 0 }" class="roboto">{{ numberWithCommas(item.pointAmt) }}</td>

                <td :class="{ 'fc-red': item.memBetAmt < 0 }" class="roboto">{{ Number(item.preRoll).toFixed(2) }}</td>
                <td :class="{ 'fc-red': item.memBetAmt < 0 }" class="roboto">{{ Number(item.postRoll).toFixed(2) }}</td>
              </tr>
            </tbody>
            <tbody class="family-wrap total">
              <tr>
                <td>{{ $t('searchArea.total') }}</td>
                <td :class="{ 'fc-red': totalData.memBetAmt < 0 }" class="roboto">{{ numberWithCommas(totalData.memBetAmt) }}</td>
                <td :class="{ 'fc-red': totalData.blackMemBetAmt < 0 }" class="roboto">{{ numberWithCommas(totalData.blackMemBetAmt) }}</td>
                <td :class="{ 'fc-red': totalData.totalMemBetAmt < 0 }" class="roboto">{{ numberWithCommas(totalData.totalMemBetAmt) }}</td>

                <td :class="{ 'fc-red': totalData.memWlAmt < 0 }" class="roboto">{{ numberWithCommas(totalData.memWlAmt) }}</td>
                <td :class="{ 'fc-red': totalData.blackWlBetAmt < 0 }" class="roboto">{{ numberWithCommas(totalData.blackWlBetAmt) }}</td>
                <td :class="{ 'fc-red': totalData.totalMemWlAmt < 0 }" class="roboto">{{ numberWithCommas(totalData.totalMemWlAmt) }}</td>

                <td class="roboto">{{ numberWithCommas(totalData.memCnt) }}</td>
                <td class="roboto">{{ numberWithCommas(totalData.blackMemCnt) }}</td>
                <td class="roboto">{{ numberWithCommas(totalData.totalMemCnt) }}</td>

                <td :class="{ 'fc-red': totalData.pointAmt < 0 }" class="roboto">{{ numberWithCommas(totalData.pointAmt) }}</td>

                <td :class="{ 'fc-red': totalData.preRoll < 0 }" class="roboto">{{ totalData.preRoll.toFixed(2) }}</td>
                <td :class="{ 'fc-red': totalData.postRoll < 0 }" class="roboto">{{ totalData.postRoll.toFixed(2) }}</td>
              </tr>
            </tbody>
          </template>
          <template v-else>
            <td colspan="11">{{ $t('txt.noData') }}</td>
          </template>
        </table>
      </div>
    </div>
  </section>
</template>

<script>
import lodash from 'lodash'
import SearchFilter from '@/components/common/SearchFilter'
import DateSelector from '@/components/common/DateSelector'
import TableHead from '@/components/main/table/Head.vue'
import UiCheck from '@/components/ui/UiCheckSet'
import subTitle from '@/components/main/PageSubTitle'
import { reportGameType, getCode } from '@/api/member.js'
import { GAME_INFO_LIST, GAME_CODE_NAME } from '@/libs/constants'
import { replaceDateT, numberWithCommas, getDateStr } from '@/libs/utils.js'
import Pagination from '@/components/common/Pagination'
import Memo from '@/components/common/memo'

export default {
  name: 'BetList',
  components: {
    SearchFilter,
    DateSelector,
    TableHead,
    UiCheck,
    subTitle,
    Pagination,
    Memo
  },
  data: function () {
    return {
      currentTab: 'all',
      tableData: {},
      headInfo: {
        fstColumn: false,
        dataList: ['index', 'memId', 'betType', 'betCnt', 'betAmt', 'specialBetAmt', 'wl', 'contractorTotal']
      },
      reqData: {
        startDate: '',
        endDate: '',
        memDelYn: 'N'
      },
      pageInfo: {},
      betList: [],
      codeList: [],
      gameType: {},
      gameList: [],
      dateConfigs: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i'
      },
      startDefault: '',
      endDefault: '',
      totalData: {
        blackMemBetAmt: 0,
        blackMemCnt: 0,
        blackWlBetAmt: 0,
        memBetAmt: 0,
        memCnt: 0,
        memWlAmt: 0,
        totalMemBetAmt: 0,
        totalMemCnt: 0,
        totalMemWlAmt: 0,
        pointAmt: 0,
        preRoll: 0,
        postRoll: 0
      }
    }
  },
  watch: {
    totalWlYn () {
      if (!this.totalWlYn) {
        if (this.headInfo.dataList.indexOf('betType') === -1) {
          this.headInfo.dataList.splice(2, 0, 'betType')
        }
      } else {
        this.headInfo.dataList.splice(2, 1)
      }
    },
    gameList: {
      deep: true,
      handler (gameList) {
        for (const item of gameList) {
          if (!item.yn) {
            this.gameAllYn = false
            break
          }
        }
      }
    }
  },
  methods: {
    pageSeach () {
      this.setTableData()
    },
    gameAllChk (e) {
      const target = e.target
      const val = target.checked
      console.log(val)
      for (const item of this.gameList) {
        item.yn = val
      }
    },
    setOldYn (data) {
      this.reqData.oldYn = data
    },
    setStartDate (date) {
      // console.log(date[0]);
      let _date = ''
      if (!date) {
        _date = new Date()
      } else {
        _date = date[0]
      }
      this.startDefault = _date
      this.reqData.startDate = getDateStr(_date, 'yyyy-MM-dd')
    },
    setEndDate (date) {
      // console.log(date[0]);
      let _date = ''
      if (!date) {
        _date = new Date()
      } else {
        _date = date[0]
      }
      this.endDefault = _date
      this.reqData.endDate = getDateStr(_date, 'yyyy-MM-dd')
    },
    async getGameCode (masterCode) {
      return await getCode(masterCode)
    },
    async setGameList () {
      const gameList = await this.getGameCode('kplay')
      console.log(gameList)
      if (gameList.length !== 0) {
        for (const item of gameList) {
          const groupCode = item.groupCode
          const gameCode = item.code
          const gTxtCode = GAME_INFO_LIST[groupCode][gameCode.toString()]
          if (gTxtCode) {
            const gameTextKey = this.$t(`gameList.${gTxtCode}`)
            const rateObj = {
              gameTxtKey: gameTextKey,
              gameType: gameCode
            }
            this.gameList.push(rateObj)
          }
        }
      }
    },
    goSearch () {},
    async setTableData () {
      this.emitter.emit('Loading', true)
      const req = lodash.cloneDeep(this.reqData)
      for (const key in req) {
        if (typeof req[key] === 'boolean') {
          if (req[key]) {
            req[key] = ''
          } else {
            req[key] = 'N'
          }
        }
      }
      const res = await reportGameType(req)
      console.log(req, res)
      if (res.resultCode === '0') {
        const dataList = res.data.list
        const totalData = {
          blackMemBetAmt: 0,
          blackMemCnt: 0,
          blackWlBetAmt: 0,
          memBetAmt: 0,
          memCnt: 0,
          memWlAmt: 0,
          totalMemBetAmt: 0,
          totalMemCnt: 0,
          totalMemWlAmt: 0,
          pointAmt: 0,
          preRoll: 0,
          postRoll: 0
        }
        for (const item of dataList) {
          item.preRoll = (item.memWlAmt / item.memBetAmt).toFixed(2)
          if (isNaN(item.preRoll)) {
            item.preRoll = 0
          }
          item.postRoll = ((item.memWlAmt - item.pointAmt) / item.memBetAmt).toFixed(2)
          if (isNaN(item.postRoll)) {
            item.postRoll = 0
          }
          totalData.blackMemCnt += item.blackMemCnt
          totalData.blackMemBetAmt += item.blackMemBetAmt
          totalData.blackWlBetAmt += item.blackWlBetAmt
          totalData.memBetAmt += item.memBetAmt
          totalData.memCnt += item.memCnt
          totalData.memWlAmt += item.memWlAmt
          totalData.totalMemBetAmt += item.memBetAmt + item.blackMemBetAmt
          totalData.totalMemCnt += item.memCnt + item.blackMemCnt
          totalData.totalMemWlAmt += item.memWlAmt + item.blackWlBetAmt
          totalData.pointAmt += item.pointAmt

          totalData.preRoll += Number(item.preRoll)
          totalData.postRoll += Number(item.postRoll)
        }
        this.tableData = dataList
        // totalData.preRoll = totalData.preRoll.toFixed(2)
        // totalData.postRoll = totalData.preRoll.toFixed(2)
        this.totalData = totalData
      }
      this.emitter.emit('Loading', false)
    }
  },
  async created () {
    this.emitter.emit('Loading', true)
    this.setStartDate()
    this.setEndDate()
    await this.setGameList()
    await this.setTableData(1)
    this.emitter.emit('Loading', false)
    // this.setGameType();
    // this.lastDataRefector(this.reqData.gameType, 1);
  }
}
</script>

<style scoped>
.toggle-item {flex-direction: row-reverse;align-items: center;}
.main-contents-wrapper > div {
  padding: 0;
  border: 0;
}
.main-sub-data-item {
  flex: 1 1 0;
  margin-right: 10px;
  border: 1px solid #c7c7c7;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 3px 3px 12px 0px #d1d1d1;
  display: flex;
}
.main-sub-data-item > div {
  padding: 8px 0;
  text-align: center;
  box-sizing: border-box;
}
.sub-data-item-title {
  background: #ab2926;
  color: #fff;
  font-weight: 700;
  width: 35%;
}
.sub-data-item-content {
  font-weight: 700;
  text-align: right;
  width: 65%;
}
.main-sub-data-item:last-child {
  margin-right: 0;
}
.page-contents-wrap {
  margin-bottom: 10px;
  display: flex;
}
.page-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.btn-total-data {
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  background: #2f4858;
  width: fit-content;
  border-radius: 3px;
  border: 2px solid #2f4858;
}
.btn-total-data:hover {
  color: #2f4858;
  background: #f1f1e6;
}
.btn-total-data > i {
  margin-right: 5px;
}
.btn-total-data > span {
  font-weight: 700;
  letter-spacing: 2px;
}
.mainTable thead {
  box-shadow: none;
  filter: none;
}
.mainTable th {border: solid 3px #efefef; height: 28px;background: #dfdfdf;}
.mainTable tbody {border: solid 3px #efefef; }
.mainTable td {height: 30px;border: solid 3px #efefef;}
</style>
